@import "../../../common/styles/mixins";

#Section2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 60px;
    @include tablet {
        padding-top: 64px;
    }
    @include laptop {
        padding-top: 85px;
    }
    @include desktop {
        padding-top: 120px;
    }

    .section2-title-wrapper {
        margin-bottom: 20px;
        @include tablet {
            margin-bottom: 28px;
        }
        @include laptop {
            margin-bottom: 42px;
        }
        @include desktop {
            margin-bottom: 56px;
        }

        .section2-title-main {
            margin-bottom: 24px;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -0.02em;
            color: #1b2a3d;
            @include tablet {
                font-size: 23px;
                line-height: 28px;
            }
            @include laptop {
                font-size: 31px;
                line-height: 37px;
            }
            @include desktop {
                font-size: 44px;
                line-height: 52px;
            }
        }

        .section2-title-sub {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #282828;
            @include tablet {
                font-size: 13px;
                line-height: 18px;
            }
            @include laptop {
                font-size: 17px;
                line-height: 24px;
            }
            @include desktop {
                font-size: 24px;
                line-height: 34px;
            }
        }
    }

    .section2-card-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include tablet {
            flex-direction: row;
            justify-content: space-between;
        }
    }
}
