@import 'variable';

@mixin tablet {
  @media only screen and (min-width: $min-width-tablet) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (min-width: $min-width-laptop) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $min-width-desktop) {
    @content;
  }
}

@mixin media($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}


@mixin font-face-OTF($name, $file, $weight: noraml, $style: normal) {
  @font-face {
    font-family: '#{$name}';
    font-weight: $weight;
    font-style: $style;
    src: url('../assets/fonts/#{$file}.otf') format('truetype');
  }
}

@mixin font-face-TTF($name, $file, $weight: noraml, $style: normal) {
  @font-face {
    font-family: '#{$name}';
    font-weight: $weight;
    font-style: $style;
    src: url('../asset/fonts/#{$file}.ttf') format('truetype');
  }
}

// @include font-face('설정할 폰트 이름', '폰트 파일 이름');
// @include font-face('설정할 폰트 이름', '폰트 파일 이름', bold);

