@import "../../styles/mixins";

.textarea-common {
    width: 100%;
    resize: none;

    &::placeholder {
        color: #b1b1c1;
    }

    &:focus {
        outline: none;
        border: solid 2px #2657dc;
    }

    &:read-only {
        outline: none;
        border: solid 2px #e7e9ef;
    }

    &:disabled {
        padding: 13px 20px;
        border: solid 1px #e7e9ef;
        background-color: white;

        &::placeholder {
            color: #d2d3d6;
        }
    }
}

.textarea-contact {
    width: 100%;
    height: 200px;
    resize: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    border: none;
    font-family: Inter;
    @include tablet {
        height: 107px;
        font-size: 11px;
        line-height: 15px;
    }
    @include laptop {
        height: 142px;
        font-size: 14px;
        line-height: 18px;
    }
    @include desktop {
        height: 200px;
        font-size: 16px;
        line-height: 22px;
    }

    &:focus {
        outline: none;
    }

    &::placeholder {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: #c2c4d0;
        @include tablet {
            font-size: 11px;
            line-height: 18px;
        }
        @include laptop {
            font-size: 14px;
            line-height: 24px;
        }
        @include desktop {
            font-size: 20px;
            line-height: 34px;
        }
    }
}
