@import "../../styles/mixins";

#Footer {
  padding: 0 20px 50px;
  box-sizing: border-box;
  width: 375px;
  margin: 0 auto;
  @include tablet {
    width: 768px;
    padding: 0 44px 75px;
  }
  @include laptop {
    width: 1024px;
    padding: 0 85px 100px;
  }
  @include desktop {
    width: 1440px;
    padding: 0 120px 140px;
  }
  .footer-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include tablet {
      flex-direction: row;
      margin-bottom: 92px;
    }
    @include laptop {
      margin-bottom: 122px;
    }
    @include desktop {
      margin-bottom: 172px;
    }
    .footer-info-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 56px;
      grid-column-gap: 122px;
      // justify-content: space-between;
      margin-bottom: 72px;
      @include tablet {
        display: flex;
        grid-column-gap: 0;
        margin-bottom: 0;
      }
      @include laptop {
      }
      @include desktop {
      }

      .footer-info {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: flex-start;
        // margin-right: 92px;
        @include tablet {
          margin-right: 69px;
        }
        @include laptop {
          margin-right: 92px;
        }
        @include desktop {
          margin-right: 130px;
        }
        .footer-info-title {
          margin-bottom: 32px;
          font-weight: 700;
          font-size: 16px;
          line-height: 40px;
          color: #18181b;
          @include tablet {
            margin-bottom: 22px;
            font-size: 12px;
            line-height: 13px;
          }
          @include laptop {
            margin-bottom: 29px;
            font-size: 13px;
            line-height: 17px;
          }
          @include desktop {
            margin-bottom: 41px;
            font-size: 16px;
            line-height: 24px;
          }
        }
        .footer-info-sub-menu {
          text-align: left;
          font-size: 16px;
          line-height: 40px;
          cursor: pointer;
          &.true {
            color: #4f60844d;
            cursor: auto;
          }
          @include tablet {
            font-size: 11px;
          }
          @include laptop {
            font-size: 13px;
          }
          @include desktop {
            font-size: 16px;
          }
        }
      }
    }
    .sns-link-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 72px;
      @include tablet {
        margin-bottom: 0;
        grid-column-gap: 21px;
        justify-content: flex-end;
        height: 15px;
      }
      @include laptop {
        grid-column-gap: 28px;
        height: 18px;
      }
      @include desktop {
        grid-column-gap: 40px;
        height: 20px;
      }
      .sns-link {
        height: 20px;
        // margin-right: 40px;
        cursor: pointer;
        @include tablet {
          height: 15px;
        }
        @include laptop {
          height: 18px;
        }
        @include desktop {
          height: 20px;
        }
      }
    }
  }
  .eqbr-info-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: space-between;
    @include tablet {
      flex-direction: row;
    }
    @include laptop {
    }
    @include desktop {
    }

    .eqbr-info {
      flex-direction: row;
      font-weight: 700;
      font-size: 14px;
      line-height: 5px;
      @include tablet {
        font-size: 7px;
        line-height: 11px;
      }
      @include laptop {
        font-size: 10px;
        line-height: 14px;
      }
      @include desktop {
        font-size: 14px;
        line-height: 20px;
      }
      &.policy {
        font-weight: 400;
        margin-bottom: 32px;
        cursor: pointer;
        @include tablet {
          margin-bottom: 0px;
        }
      }
    }
    .policy-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      // width: 103px;
      font-size: 14px;
      @include tablet {
        // width: 210px;
        width: 164px;
      }
      @include laptop {
        // width: 280px;
        width: 180px;
      }
      @include desktop {
        // width: 394px;
        width: 254px;
      }
    }
  }
}
