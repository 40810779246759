@import "../../../../common/styles/mixins";

#Section3WhisperCard {
    display: flex;
    flex-direction: column;
    box-shadow: -4px 4px 24px rgba(169, 166, 200, 0.25);
    border-radius: 16px;
    width: 335px;
    height: 476px;
    margin-bottom: 35px;
    overflow: hidden;
    background-color: #1d74ff;
    @include tablet {
        width: 205px;
        height: 271px;
        margin-bottom: 0;
    }
    @include laptop {
        width: 273px;
        height: 338px;
    }
    @include desktop {
        width: 384px;
        height: 476px;
    }

    .section3-whisper-card-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: #ffffff;

        .section3-whisper-card-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            //object-fit: contain;
        }
    }

    .section3-whisper-card-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 15px 20px;
        @include tablet {
            padding: 8px 11px;
        }
        @include laptop {
            padding: 11px 14px;
        }
        @include desktop {
            padding: 15px 20px;
        }

        .section3-whisper-card-text-wrapper {
            display: flex;
            flex-direction: column;
            margin-right: 26px;
            @include tablet {
                margin-right: 14px;
            }
            @include laptop {
                margin-right: 18px;
            }
            @include desktop {
                margin-right: 26px;
            }

            .section3-whisper-card-text-main {
                margin-bottom: 4px;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                background: linear-gradient(
                                266.17deg,
                                #8fc9ff 46.86%,
                                #b5b4ff 99.96%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                @include tablet {
                    font-size: 12px;
                    line-height: 12px;
                }
                @include laptop {
                    font-size: 15px;
                    line-height: 16px;
                }
                @include desktop {
                    font-size: 18px;
                    line-height: 22px;
                }
            }

            .section3-whisper-card-text-sub {
                font-weight: 600;
                font-size: 26px;
                line-height: 31px;
                color: #ffffff;
                @include tablet {
                    font-size: 14px;
                    line-height: 17px;
                }
                @include laptop {
                    font-size: 18px;
                    line-height: 22px;
                }
                @include desktop {
                    font-size: 26px;
                    line-height: 31px;
                }
            }
        }

        .section3-whisper-card-link-btn {
            width: 36px;
            object-fit: contain;
            cursor: pointer;
            @include tablet {
                width: 19px;
            }
            @include laptop {
                width: 26px;
            }
            @include desktop {
                width: 36px;
            }
        }
    }
}
