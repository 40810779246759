@import "../../../common/styles/mixins";

#NoticeList {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 375px;
  padding: 84px 0 53px;

  @include tablet {
    padding: 97px 40px 80px;
    align-items: center;
    width: 768px;
  }

  @include laptop {
    padding: 117px 85px 100px;
    width: 1024px;
  }

  @include desktop {
    padding: 147px 120px 120px;
    width: 1440px;
  }

  .notice-background-circle {
    z-index: -1;
    position: absolute;
    flex-shrink: 0;
    background: #26abeb;
    filter: blur(76px);

    &.one {
      top: 400px;
      right: 0px;
      width: 457px;
      height: 717px;
      border-radius: 717px;
      opacity: 0.13;
    }

    &.two {
      top: 860px;
      right: 0;
      width: 172px;
      height: 344px;
      border-radius: 344px;
      opacity: 0.36;
    }

    &.three {
      top: 300px;
      left: 0;
      width: 533px;
      height: 717px;
      border-radius: 717px;
      opacity: 0.05;
    }
  }

  .notice-header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    padding: 0 20px;

    @include tablet {
      padding: 0;
      align-items: center;
    }

    @include laptop {
    }

    @include desktop {
      margin-bottom: 68px;
    }

    .notice-header-icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      border-radius: 30px;
      background: #fff;
      border: 1px solid #e9e7e7;

      .notice-header-icon {
        width: 25px;
        object-fit: contain;
      }
    }

    .notice-header-title {
      margin: 30px 0;
      color: #252a3f;
      font-size: 31px;
      font-weight: 700;
      line-height: 41px;
      text-transform: capitalize;

      @include tablet {
        margin: 19px 0 7px;
        font-size: 32px;
      }

      @include laptop {
      }

      @include desktop {
      }
    }
  }

  .notice-list {
    width: 100%;
    background: #fff;
    box-shadow: none;
    border-radius: 0px;

    @include tablet {
      border-radius: 16px;
      box-shadow: 0px 4px 10px 0px rgba(127, 135, 158, 0.15);
    }

    @include laptop {
    }

    @include desktop {
    }

    .notice-list-header-row {
      display: none;

      @include tablet {
        display: grid;
        grid-template-columns: 1266fr 6366fr 2416fr;
        border-bottom: 1px solid var(--lgrey-02, #efefef);
      }

      @include laptop {
        grid-template-columns: 1366fr 6466fr 2816fr;
      }

      @include desktop {
        grid-template-columns: 1266fr 6766fr 2016fr;
      }

      .notice-list-header {
        padding: 26px 20px;
        color: #252a3f;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;

        @include tablet {
          padding: 18px 14px;
        }

        @include laptop {
          padding: 22px 19px;
        }

        @include desktop {
          padding: 26px 24px;
        }
      }
    }

    .notice-list-row {
      border-bottom: 1px solid var(--lgrey-02, #efefef);
      cursor: pointer;

      &.hover {
        background: #f8f9fd;
      }

      @include tablet {
        display: grid;
        grid-template-columns: 1266fr 6366fr 2416fr;
      }

      @include laptop {
        grid-template-columns: 1366fr 6466fr 2816fr;
      }

      @include desktop {
        grid-template-columns: 1266fr 6766fr 2016fr;
      }

      .notice-list-item {
        padding: 26px 20px;
        max-width: 100%;
        color: #51535b;
        font-size: 14px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.type,
        &.date {
          display: none;
          color: #8a8e9e;
        }

        &.title {
          cursor: pointer;

          &.hover {
            color: #000;
            font-weight: 700;
          }
        }

        @include tablet {
          padding: 18px 14px;

          &.type,
          &.date {
            display: block;
          }

          &.title {
            max-width: 460px;
          }
        }

        @include laptop {
          padding: 22px 19px;

          &.title {
            max-width: 500px;
          }
        }

        @include desktop {
          padding: 26px 24px;

          &.title {
            max-width: 540px;
          }
        }
      }
    }
  }

  .paging-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 34px;
    width: 100%;

    @include tablet {
      justify-content: center;
      margin-top: 68px;
      padding: 0 30px;
    }

    @include laptop {
      padding: 0 40px;
    }

    @include desktop {
      padding: 0 50px;
    }

    .paging-select-wrapper {
      display: flex;
      align-items: center;
      gap: 9px;
    }
  }
}
