@import "../../styles/mixins";

.input-main {
    border-radius: 6px;
    border: solid 1px #e5e9ef;
    background-color: #ffffff;

    &::placeholder {
        font-size: 12px;
        color: #666f73;
    }

    &:focus {
        outline: none;
    }

    &:read-only {
        background-color: #ececec;
    }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-contact {
    width: 335px;
    padding: 12px 16px;
    background: #ffffff;
    border: 1px solid #F0F0F2;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    @include tablet {
        width: 265px;
        height: 36px;
        padding: 8px 11px;
        border-radius: 4px;
        font-size: 11px;
        line-height: 15px;
    }
    @include laptop {
        width: 353px;
        height: 48px;
        padding: 10px 14px;
        border-radius: 5.5px;
        font-size: 14px;
        line-height: 18px;
    }
    @include desktop {
        width: 496px;
        height: 68px;
        padding: 12px 16px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
    }

    &::placeholder {
        font-weight: 500;
        font-size: 20px;
        line-height: 34px;
        color: #c2c4d0;
        @include tablet {
            font-size: 11px;
            line-height: 18px;
        }
        @include laptop {
            font-size: 14px;
            line-height: 24px;
        }
        @include desktop {
            font-size: 20px;
            line-height: 34px;
        }
    }

    &:focus {
        outline: none;
        border-color: #3B37FF;
    }
}
