@import "src/view/common/styles/variable";
@import "../../styles/mixins";

header {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    z-index: 100;
    top: 0;
    -webkit-transition: background-color 0.4s;
    -moz-transition: background-color 0.4s;
    -ms-transition: background-color 0.4s;
    -o-transition: background-color 0.4s;
    transition: background-color 0.4s;

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 375px;
        height: 70px;
        padding: 0 20px;
        @include tablet {
            max-width: 768px;
            padding: 0 40px;
            height: 37px;
        }
        @include laptop {
            max-width: 1024px;
            padding: 0 85px;
            height: 50px;
        }
        @include desktop {
            max-width: 1440px;
            padding: 0 120px;
            height: 70px;
        }

        .header-logo-container {
            display: flex;
            align-items: center;
            height: 100%;

            .header-logo {
                height: 20px;
                object-fit: contain;
                cursor: pointer;
                @include tablet {
                    height: 17px;
                }
                @include laptop {
                    height: 23px;
                }
                @include desktop {
                    height: 32px;
                }
            }
        }

        .mobile-menu-toggle {
            width: 40px;
            height: 40px;
            object-fit: cover;
            cursor: pointer;
        }

        .header-menu {
            display: flex;
            align-items: center;
            gap: 20px;
            @include tablet {
                gap: 14px;
            }
            @include laptop {
                gap: 19px;
            }
            @include desktop {
                gap: 27px;
            }

            &-menu {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px 8px;
                border-radius: 40px;
                cursor: pointer;
                color: #0e0e0e;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px; /* 171.429% */
                text-decoration: none;

                @include tablet {
                    font-size: 8px;
                    line-height: 13px; /* 171.429% */
                }

                @include laptop {
                    font-size: 10px;
                    line-height: 17px; /* 171.429% */
                }

                @include desktop {
                    font-size: 14px;
                    line-height: 24px; /* 171.429% */
                }


                &:hover {
                    background-color: #f8f9fd;
                }


                &.gradient {
                    background: linear-gradient(95.06deg, #6b6dfc 0%, #4ba9ff 100%);
                    color: #ffffff;
                }
            }
        }
    }
}

.mobile-menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 375px;
    padding: 40px 0;
    overflow-y: auto;
    //&::-webkit-scrollbar {
    //  width: 4px;  /* 스크롤바의 너비 */
    //}
    //&::-webkit-scrollbar-thumb {
    //  background: #3F4553; /* 스크롤바의 색상 */
    //}
    .mobile-menu-top {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-menu {
            display: flex;
            align-items: center;
            width: 100%;
            height: 72px;
            padding: 16px 20px;
            color: #51535b;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            border-bottom: 1px solid #efefef;
            cursor: pointer;
        }
    }

    .mobile-menu-bottom {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 20px;

        .mobile-sns-link-wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 60px;

            .mobile-sns-link {
                height: 20px;
                cursor: pointer;
            }
        }

        .mobile-policy-wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;

            .mobile-policy {
                color: #4f6084;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                cursor: pointer;
            }
        }

        .mobile-copyright {
            color: #4f6084;
            font-family: Public Sans;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px; /* 150% */
        }
    }
}
