@import "../../../common/styles/mixins";

#Main {
    width: 375px;
    padding: 60px 20px;
    margin: 0 auto;

    @include tablet {
        width: 768px;
        padding: 40px;
        margin-bottom: 123px;
    }

    @include laptop {
        width: 1024px;
        padding: 85px;
        margin-bottom: 164px;
    }

    @include desktop {
        width: 1440px;
        padding: 120px;
        margin-bottom: 230px;
    }

    .background {
        display: flex;
        position: absolute;
        top: -30px;
        right: 0;
        width: 300px;
        z-index: -1;
        object-fit: contain;

        @include tablet {
            top: 0;
            width: 400px;
        }

        @include laptop {
            right: 0;
            width: 533px;
        }

        @include desktop {
            width: 750px;
        }
    }

    .bg-circle1 {
        z-index: -1;
        display: flex;
        position: absolute;
        top: 447px;
        left: -54px;
        width: 370px;
        height: 370px;
        background: #26abeb;
        opacity: 0.05;
        filter: blur(76px);

        @include tablet {
            top: 327px;
            left: -184px;
            width: 382px;
            height: 382px;
        }

        @include laptop {
            top: 357px;
            left: -184px;
            width: 510px;
            height: 510px;
        }

        @include desktop {
            top: 447px;
            left: -184px;
            width: 717px;
            height: 717px;
        }
    }

    .bg-circle2 {
        z-index: -1;
        position: absolute;
        width: 717px;
        height: 717px;
        right: 0;
        top: 2663px;
        background: #26abeb;
        opacity: 0.13;
        filter: blur(76px);

        @include tablet {
            top: 327px;
            left: -198px;
            width: 382px;
            height: 382px;
        }

        @include laptop {
            top: 357px;
            left: -131px;
            width: 510px;
            height: 510px;
        }

        @include desktop {
            top: 447px;
            left: -184px;
            width: 717px;
            height: 717px;
        }
    }

    .bg-circle3 {
        z-index: -1;
        position: absolute;
        background: #26abeb;
        opacity: 0.36;
        filter: blur(76px);

        @include tablet {
            top: 1667px;
            right: 0px;
            width: 183px;
            height: 183px;
        }

        @include laptop {
            top: 2223px;
            width: 245px;
            height: 245px;
        }

        @include desktop {
            top: 3126px;
            width: 344px;
            height: 344px;
        }
    }

    .bg-circle4 {
        z-index: -1;
        position: absolute;
        top: 3249px;
        left: -111px;
        width: 370px;
        height: 370px;
        background: #26abeb;
        opacity: 0.05;
        filter: blur(76px);

        @include tablet {
            top: 1733px;
            left: -59px;
            width: 382px;
            height: 382px;
        }

        @include laptop {
            top: 2310px;
            left: -79px;
            width: 510px;
            height: 510px;
        }

        @include desktop {
            top: 3249px;
            left: -111px;
            width: 717px;
            height: 717px;
        }
    }
}

.list-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 36px;
    height: 36px;
    background-color: #1d74ff;
    border-radius: 100%;
    cursor: pointer;

    @include tablet {
        width: 23px;
        height: 23px;
    }

    @include laptop {
        width: 26px;
        height: 26px;
    }

    @include desktop {
        width: 36px;
        height: 36px;
    }

    .round {
        position: absolute;
        top: -3px;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        cursor: pointer;

        @include tablet {
            top: -7px;
            width: 23px;
            height: 23px;
        }

        @include laptop {
            top: -6px;
            left: -1px;
            width: 26px;
            height: 26px;
        }

        @include desktop {
            left: 0;
            top: -3px;
            width: 36px;
            height: 36px;
        }
    }

    #cta {
        width: 100%;
        cursor: pointer;
        position: absolute;
    }

    #cta .arrow {
        left: 30%;
    }

    .arrow {
        position: absolute;
        bottom: 0;
        margin-left: 0px;
        width: 12px;
        height: 12px;
        background-size: cover;
        top: 15px;

        @include tablet {
            width: 7px;
            height: 7px;
        }

        @include laptop {
            width: 9px;
            height: 9px;
        }

        @include desktop {
            width: 12px;
            height: 12px;
        }
    }

    .segunda {
        margin-left: 8px;

        @include tablet {
            margin-left: 4px;
        }

        @include laptop {
            margin-left: 6px;
        }

        @include desktop {
            margin-left: 8px;
        }
    }

    .next {
        background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
    }

    @keyframes bounceAlpha {
        0% {
            opacity: 1;
            transform: translateX(0px) scale(1);
        }

        25% {
            opacity: 0;
            transform: translateX(10px) scale(0.9);
        }

        26% {
            opacity: 0;
            transform: translateX(-10px) scale(0.9);
        }

        55% {
            opacity: 1;
            transform: translateX(0px) scale(1);
        }
    }

    .bounceAlpha {
        animation-name: bounceAlpha;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    .arrow.primera.bounceAlpha {
        animation-name: bounceAlpha;
        animation-duration: 1.4s;
        animation-delay: 0.2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    .round:hover .arrow {
        animation-name: bounceAlpha;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    .round:hover .arrow.primera {
        animation-name: bounceAlpha;
        animation-duration: 1.4s;
        animation-delay: 0.2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    &.white {
        background-color: #ffffff;

        .round {
            @include tablet {
                top: -7px;
            }

            @include laptop {
                top: -6px;
                left: 1px;
            }

            @include desktop {
                top: -5px;
                left: -0px;
            }
        }

        .segunda {
            margin-left: 8px;

            @include tablet {
                margin-left: 5px;
            }

            @include laptop {
                margin-left: 6px;
            }

            @include desktop {
                margin-left: 7px;
            }
        }

        .next {
            background-image: url("../../../common/asset/images/right-arrow.png");
            object-fit: contain;

            @include tablet {
                width: 5px;
                height: 9px;
            }

            @include laptop {
                width: 5px;
            }

            @include desktop {
                width: 9px;
                height: 16px;
            }
        }

        &.black {
            .next {
                filter: grayscale(1);
            }
        }
    }

    &.gray {
        background: #999da3;
    }
}
