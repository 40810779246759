@import "../../../../common/styles/mixins";

#Section3LargeCard {
    display: flex;
    flex-direction: column-reverse;
    height: 389px;
    background: #ffffff;
    box-shadow: -4px 4px 24px rgba(169, 166, 200, 0.25);
    border-radius: 16px;
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: 20px;
        @include tablet {
            margin-bottom: 10px;
        }
        @include laptop {
            margin-bottom: 14px;
        }
        @include desktop {
            margin-bottom: 20px;
        }
    }

    @include tablet {
        flex-direction: row;
        width: 205px;
        height: 130px;
        background: #ffffff;
        box-shadow: -4px 4px 24px rgba(169, 166, 200, 0.25);
        border-radius: 9px;
    }
    @include laptop {
        width: 273px;
        height: 162px;
        border-radius: 11px;
    }
    @include desktop {
        width: 384px;
        height: 228px;
        border-radius: 16px;
    }

    .section3-large-card-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px;
        @include tablet {
            padding: 14px;
        }
        @include laptop {
            padding: 16px;
        }
        @include desktop {
            padding: 20px;
        }

        .section3-large-card-text-content {
            .section3-large-card-title {
                margin-bottom: 12px;
                font-weight: 700;
                font-size: 26px;
                line-height: 31px;
                color: #1b2a3d;
                @include tablet {
                    margin-bottom: 6px;
                    font-size: 14px;
                    line-height: 17px;
                }
                @include laptop {
                    margin-bottom: 9px;
                    font-size: 18px;
                    line-height: 22px;
                }
                @include desktop {
                    margin-bottom: 12px;
                    font-size: 26px;
                    line-height: 31px;
                }
            }

            .section3-large-card-description {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #282828;
                @include tablet {
                    font-size: 7px;
                    line-height: 12px;
                }
                @include laptop {
                    font-size: 10px;
                    line-height: 16px;
                }
                @include desktop {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        .section3-large-card-link {
            width: 36px;
            object-fit: contain;
            cursor: pointer;
            @include tablet {
                width: 19px;
            }
            @include laptop {
                width: 26px;
            }
            @include desktop {
                width: 36px;
            }
        }
    }

    .section3-large-card-image {
        width: 100%;
        margin: 0 50px;
        object-fit: contain;

        &:last-child {
            @include tablet {
                margin: 0;
                margin-right: 8px;
                width: 95px;
            }
            @include laptop {
                width: 127px;
            }
            @include desktop {
                width: 179px;
            }
        }
    }
}
