@import "../../../common/styles/mixins";

#Section6 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    @include tablet {
        flex-direction: row;
        margin-top: 64px;
    }
    @include laptop {
        margin-top: 85px;
    }
    @include desktop {
        margin-top: 120px;
    }

    .section6-text-container {
        .section6-title {
            margin-bottom: 24px;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -0.02em;
            color: #1b2a3d;
            @include tablet {
                margin-bottom: 13px;
                font-size: 23px;
                line-height: 28px;
            }
            @include laptop {
                margin-bottom: 17px;
                font-size: 31px;
                line-height: 37px;
            }
            @include desktop {
                margin-bottom: 24px;
                font-size: 44px;
                line-height: 52px;
            }
        }

        .section6-sub-title {
            margin-bottom: 20px;
            font-weight: 500;
            font-size: 15px;
            line-height: 26px;
            color: #282828;
            white-space: pre-wrap;
            @include tablet {
                margin-bottom: 0;
                font-size: 12px;
                line-height: 18px;
            }
            @include laptop {
                font-size: 16px;
                line-height: 24px;
            }
            @include desktop {
                font-size: 22px;
                line-height: 34px;
            }
        }
    }

    .section6-contact-container {
        display: flex;
        flex-direction: column;

        .section6-input-container {
            margin-bottom: 20px;
            @include tablet {
                margin-bottom: 11px;
            }
            @include laptop {
                margin-bottom: 14px;
            }
            @include desktop {
                margin-bottom: 20px;
            }

            .section6-input-title {
                margin-bottom: 4px;

                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                color: #8F8F8F;
            }

            .section6-input-length {
                justify-self: end;
                margin-top: 2px;

                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                color: #8F8F8F;
            }

        }

        .select-contact {
            width: 335px;
            padding: 12px 16px;
            background: #ffffff;
            border: 1px solid #F0F0F2;
            border-radius: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #000000;
            outline: none;
            -webkit-appearance: none; /* for chrome */
            -moz-appearance: none; /*for firefox*/
            appearance: none;
            background: url("../../../common/asset/images/Icon_select.png") no-repeat 97% 50%/12px auto;

            @include tablet {
                width: 265px;
                height: 36px;
                padding: 8px 11px;
                border-radius: 4px;
                font-size: 11px;
                line-height: 15px;
            }
            @include laptop {
                width: 353px;
                height: 48px;
                padding: 10px 14px;
                border-radius: 5.5px;
                font-size: 14px;
                line-height: 18px;
            }
            @include desktop {
                width: 496px;
                height: 68px;
                padding: 12px 16px;
                border-radius: 8px;
                font-size: 16px;
                line-height: 20px;
            }
        }

        .section6-textarea-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 335px;
            height: 128px;
            padding: 12px 16px;
            background: #ffffff;
            border: 1px solid #F0F0F2;
            border-radius: 8px;
            @include tablet {
                align-items: flex-end;
                width: 265px;
                height: 160px;
                padding: 8px 11px;
                border-radius: 4px;
            }
            @include laptop {
                width: 353px;
                height: 213px;
                padding: 10px 14px;
                border-radius: 5.5px;
            }
            @include desktop {
                width: 496px;
                height: 128px;
                padding: 12px 16px;
                border-radius: 8px;
            }
        }

        .section6-contact-button {
            padding: 13px;
            background: linear-gradient(90deg, #1D74FF 0%, #3B37FF 100%);
            outline: none;
            border: none;
            border-radius: 8px;

            font-size: 16px;
            line-height: 26px;
            font-weight: 500;
            color: #ffffff;
            cursor: pointer;

            @include tablet {
                font-size: 11px;
                line-height: 15px;
            }

            @include laptop {
                font-size: 14px;
                line-height: 18px;
            }

            @include desktop {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}
