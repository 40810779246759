.paging {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;

  .paging-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #efefef;
    border-radius: 10px;
    height: 100%;
    cursor: pointer;

    &.disabled {
      filter: grayscale(1);
      cursor: default;
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    padding: 0 16px;

    &-src {
      width: 7px;
      object-fit: contain;
    }
  }

  &-label {
    display: flex;
    justify-content: center;
    width: 82px;
    font-size: 14px;
    line-height: 24px;
    color: #1d74ff;
  }

  &-current {
    padding: 0 38px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    cursor: default;
  }
}
