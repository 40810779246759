@import "../../../common/styles/mixins";

#NoticeDetail {
  width: 375px;
  padding: 48px 20px 284px;

  @include tablet {
    width: 768px;
    padding: 54px 40px 284px;
  }
  @include laptop {
    width: 1024px;
    padding: 62px 85px 284px;
  }
  @include desktop {
    width: 1440px;
    padding: 76px 120px 284px;
  }

  .notice-detail {
    &-back {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 40px;
      cursor: pointer;

      @include tablet {
        margin-bottom: 48px;
      }
      @include laptop {
        margin-bottom: 72px;
      }
      @include desktop {
        margin-bottom: 100px;
      }

      &-icon {
        width: 28px;
        object-fit: contain;
      }

      &-label {
        font-size: 18px;
        color: #252a3f;
      }
    }

    &-header {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #cdcdcd;

      @include tablet {
      }
      @include laptop {
        gap: 32px;
        padding-bottom: 40px;
      }
      @include desktop {
      }

      &-type {
        padding: 8px 28px;
        width: fit-content;
        border-radius: 10px;
        background-color: #eff2fa;
        color: #252a3f;
        font-weight: 700;
        font-size: 20px;

        @include tablet {
        }
        @include laptop {
          font-size: 24px;
        }
        @include desktop {
        }
      }

      &-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 1.3;
        color: #252a3f;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;

        @include tablet {
        }
        @include laptop {
          font-size: 48px;
          line-height: 58px;
        }
        @include desktop {
        }
      }

      &-date {
        font-size: 18px;
        color: #8a8e9e;
      }
    }

    &-body {
      padding: 20px 0 0;
      font-size: 18px;
      line-height: 32px;
      color: #232e52;
      word-break: keep-all;
      // white-space: pre-line;

      @include tablet {
      }
      @include laptop {
        padding: 40px 0 0 40px;
      }
      @include desktop {
      }
    }
  }
}
