@import "../../../common/styles/mixins";

#Section1 {
    display: flex;
    flex-direction: column;

    .section1-text-container {
        display: flex;
        flex-direction: column;

        .section1-main-text-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;

            .section1-main-text {
                font-weight: 700;
                font-size: 32px;
                line-height: 40px;
                letter-spacing: -0.02em;
                color: #0e0e0e;
                @include tablet {
                    font-size: 34px;
                    line-height: 38px;
                }
                @include laptop {
                    font-size: 46px;
                    line-height: 51px;
                }
                @include desktop {
                    font-size: 64px;
                    line-height: 72px;
                }

                &.gradient {
                    background: linear-gradient(
                                    266.17deg,
                                    #4aa8ff 46.86%,
                                    #6c69fb 99.96%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        .section1-sub-text {
            margin-bottom: 40px;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            color: #100f0f;
            white-space: pre-wrap;
            @include tablet {
                margin-bottom: 32px;
                font-size: 13px;
                line-height: 18px;
            }
            @include laptop {
                margin-bottom: 43px;
                font-size: 17px;
                line-height: 24px;
            }
            @include desktop {
                margin-bottom: 60px;
                font-size: 24px;
                line-height: 34px;
            }
        }

        .section1-btn-wrapper {
            display: flex;

            .section1-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 12px;
                border-radius: 40px;
                background: linear-gradient(95.06deg, #6b6dfc 0%, #4ba9ff 100%);
                cursor: pointer;
                text-decoration: none;

                &.no-bg {
                    margin-right: 0;
                    border: 1px solid transparent;
                    background-image: linear-gradient(#fff, #fff),
                    linear-gradient(92.56deg, #6b6dfc 0%, #4ba9ff 100%);
                    background-origin: border-box;
                    background-clip: content-box, border-box;

                    .section1-btn-text {
                        background: linear-gradient(
                                        92.56deg,
                                        #6b6dfc 0%,
                                        #4ba9ff 100%
                        );
                        text-decoration: none;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }

                .section1-btn-text {
                    margin: 10px 18px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #ffffff;
                    text-decoration: none;
                    @include tablet {
                        margin: 7px 25px;
                        font-size: 10px;
                    }
                    @include laptop {
                        margin: 10px 33px;
                        font-size: 13px;
                    }
                    @include desktop {
                        margin: 14px 47px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .section1-img-container {
        display: flex;
        flex-direction: column;
        gap: 28px;
        margin-top: 80px;
        @include tablet {
            margin-top: 64px;
        }

        @include laptop {
            margin-top: 85px;
        }
        @include desktop {
            margin-top: 120px;
        }


        .banner-container {
            display: flex;
            width: 100%;
            overflow: hidden; /* 롤링 배너 영역 벗어나는 부분 숨기기 */

            .banner_list {
                --animation-duration: 80s;
                display: flex;
                width: 1266px;
                animation: roll var(--animation-duration) linear infinite;

                @include tablet {
                    width: 2332px;

                }

                .banner {
                    display: flex;
                    justify-content: center;
                    --height: 45px;
                    width: 120px;
                    height: var(--height);
                    margin-right: 40px;

                    .banner-logo {
                        height: var(--height);
                        object-fit: contain;
                    }

                    @include tablet {
                        --height: 90px;
                        margin-right: 80px;
                    }
                }

                li {
                    list-style: none;
                }

                &.reverse {
                    animation-direction: reverse;
                }
            }

            .n1:last-child {
                animation-delay: var(--animation-duration);
            }
        }
    }

}

/* 롤링 애니메이션 정의 */
@keyframes roll {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
