@import "../../../../common/styles/mixins";

#Section5Card {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    padding: 20px;
    background: #1d74ff;
    border-radius: 16px;
    @include tablet {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 27px;
        border-radius: 9px;
    }
    @include laptop {
        padding: 0 36px;
        border-radius: 11px;
    }
    @include desktop {
        padding: 0 50px;
        border-radius: 16px;
    }

    .section5-card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include tablet {
            padding: 27px 11px 27px 0;
        }
        @include laptop {
            padding: 36px 14px 36px 0;
        }
        @include desktop {
            padding: 50px 20px 50px 0;
        }

        .section5-card-content-text {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #ffffff;
            margin: 28px 0;
            @include tablet {
                margin-top: 0;
                margin-bottom: 17px;
                font-size: 15px;
                line-height: 19px;
            }
            @include laptop {
                margin-bottom: 23px;
                font-size: 20px;
                line-height: 25px;
            }
            @include desktop {
                margin-bottom: 32px;
                font-size: 28px;
                line-height: 36px;
            }
        }

        .section5-card-link {
            width: 36px;
            object-fit: contain;
            cursor: pointer;
        }
    }

    .section5-card-image {
        width: 100%;
        object-fit: contain;
        @include tablet {
            width: auto;
            height: 175px;
        }
        @include laptop {
            height: 233px;
        }
        @include desktop {
            height: 328px;
        }
    }
}
