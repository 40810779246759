@import "../../../../common/styles/mixins";

#Section3SmallCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 228px;
    padding: 25px 20px;
    box-shadow: -4px 4px 24px rgba(169, 166, 200, 0.25);
    border-radius: 16px;
    margin-bottom: 20px;
    @include tablet {
        width: 180px;
        height: 130px;
        padding: 13px 11px;
        border-radius: 10px;
        margin-bottom: 11px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include laptop {
        width: 202px;
        height: 162px;
        padding: 18px 14px;
        border-radius: 13px;
        margin-bottom: 14px;
    }
    @include desktop {
        width: 284px;
        height: 228px;
        padding: 25px 20px;
        border-radius: 16px;
        margin-bottom: 20px;
    }

    .section3-small-card-text-container {
        .section3-small-card-title {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 26px;
            line-height: 31px;
            white-space: pre-wrap;
            @include tablet {
                margin-bottom: 5px;
                font-size: 14px;
                line-height: 13px;
            }
            @include laptop {
                margin-bottom: 7px;
                font-size: 15px;
                line-height: 17px;
            }
            @include desktop {
                margin-bottom: 10px;
                font-size: 26px;
                line-height: 31px;
            }
        }
        .section3-small-card-description {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            @include tablet {
                font-size: 5px;
                line-height: 12px;
            }
            @include laptop {
                font-size: 8px;
                line-height: 15px;
            }
            @include desktop {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }

    .section3-small-card-link-image {
        width: 36px;
        object-fit: contain;
        cursor: pointer;
        @include tablet {
            width: 19px;
        }
        @include laptop {
            width: 26px;
        }
        @include desktop {
            width: 36px;
        }
    }
    .section3-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 96px;
        padding: 9px 20px;
        border: 1px solid #ffffff;
        border-radius: 18px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
        transition: all ease 0.2s;
        cursor: pointer;
        &:hover {
            border: 1px solid #1d74ff;
            background-color: #1d74ff;
        }
        @include tablet {
            width: 60px;
            padding: 3px 0px;
            border-radius: 12px;
            font-size: 5px;
            line-height: 12px;
        }
        @include laptop {
            width: 68px;
            padding: 5px 15px;
            border-radius: 15px;
            font-size: 10px;
            line-height: 15px;
        }
        @include desktop {
            width: 96px;
            padding: 9px 20px;
            border-radius: 18px;
            font-size: 12px;
            line-height: 18px;
        }
    }
}
