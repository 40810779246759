@import "../../../../common/styles/mixins";

#Section4SmallCard {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 335px;
    padding: 24px 20px;
    box-shadow: -4px 4px 24px rgba(169, 166, 200, 0.25);
    border-radius: 16px;

    &:not(:last-child) {
        margin-bottom: 20px;
        @include tablet {
            margin-bottom: 11px;
        }
        @include laptop {
            margin-bottom: 14px;
        }
        @include desktop {
            margin-bottom: 20px;
        }
    }

    @include tablet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: 315px;
        height: 122px;
        padding: 13px 19px;
    }
    @include laptop {
        width: 420px;
        height: 162px;
        padding: 17px 26px;
    }
    @include desktop {
        width: 590px;
        height: 228px;
        padding: 24px 36px;
    }

    .section4-small-card-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        .section4-small-card-title {
            margin-bottom: 12px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #282828;
            @include tablet {
                margin-bottom: 6px;
                font-size: 7px;
                line-height: 12px;
            }
            @include laptop {
                margin-bottom: 9px;
                font-size: 10px;
                line-height: 16px;
            }
            @include desktop {
                margin-bottom: 12px;
                font-size: 14px;
                line-height: 22px;
            }
        }

        .section4-small-card-main-text {
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            color: #1b2a3d;
            @include tablet {
                font-size: 12px;
                line-height: 14px;
                white-space: pre-wrap;
            }
            @include laptop {
                font-size: 15px;
                line-height: 20px;
            }
            @include desktop {
                font-size: 22px;
                line-height: 32px;
            }
        }
    }

    .section4-small-card-main-img {
        width: 100%;
        object-fit: contain;
        @include tablet {
            width: 93px;

        }
        @include laptop {
            width: 124px;

        }
        @include desktop {
            width: 175px;

        }
    }

    .section4-small-card-link {
        width: 36px;
        object-fit: contain;
        cursor: pointer;
        @include tablet {
            width: 19px;
        }
        @include laptop {
            width: 26px;
        }
        @include desktop {
            width: 36px;
        }
    }
}
