@import "../../../../common/styles/mixins";

#Section2Card {
    width: 335px;
    margin-bottom: 20px;
    box-shadow: -4px 4px 24px rgba(169, 166, 200, 0.25);
    border: 1px solid transparent;
    border-radius: 16px;
    background-image: linear-gradient(#fff, #fff),
        linear-gradient(132.4deg, #6b6cfc 1.43%, #4ba9ff 97.83%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    @include tablet {
        margin-bottom: 0;
        width: 205px;
        height: 189px;
    }
    @include laptop {
        width: 274px;
        height: 252px;
    }
    @include desktop {
        width: 385px;
        height: 355px;
    }

    .section2-card-container {
        display: flex;
        flex-direction: column;
        padding: 30px 20px;
        @include tablet {
            padding: 14px;
        }
        @include laptop {
            padding: 18px;
        }
        @include desktop {
            padding: 30px;
        }
        .section2-card-title {
            margin-bottom: 60px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #0e0e0e;
            @include tablet {
                margin-bottom: 11px;
                font-size: 10px;
                line-height: 12px;
            }
            @include laptop {
                margin-bottom: 25px;
                font-size: 12px;
                line-height: 16px;
            }
            @include desktop {
                margin-bottom: 60px;
                font-size: 14px;
                line-height: 22px;
            }
        }

        .section2-card-content {
            display: flex;
            flex-direction: column;

            .section2-card-main-content {
                display: flex;
                flex-direction: column;
                margin-bottom: 22px;
                @include tablet {
                    margin-bottom: 12px;
                }
                @include laptop {
                    margin-bottom: 16px;
                }
                @include desktop {
                    margin-bottom: 22px;
                }

                .section2-card-image {
                    width: 28px;
                    margin-bottom: 20px;
                    object-fit: contain;
                    @include tablet {
                        height: 16px;
                        margin-bottom: 11px;
                    }
                    @include laptop {
                        height: 21px;
                        margin-bottom: 14px;
                    }
                    @include desktop {
                        height: 24px;
                        margin-bottom: 20px;
                    }
                }

                .section2-card-main-text {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 32px;
                    background: linear-gradient(
                        266.17deg,
                        #698fb2 46.86%,
                        #f36cd1 99.96%
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    white-space: pre-wrap;
                    @include tablet {
                        font-size: 14px;
                        line-height: 17px;
                    }
                    @include laptop {
                        font-size: 18px;
                        line-height: 23px;
                    }
                    @include desktop {
                        font-size: 26px;
                        line-height: 32px;
                    }
                }
            }

            .section2-card-description {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                color: #0e0e0e;
                @include tablet {
                    font-size: 11px;
                    line-height: 14px;
                }
                @include laptop {
                    font-size: 13px;
                    line-height: 18px;
                }
                @include desktop {
                    font-size: 18px;
                    line-height: 26px;
                }
            }
        }
    }
}
