@import "../../../../common/styles/mixins";

#Section4LargeCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 335px;
    height: 704px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 16px;
    background-color: #1b2a3d;
    background-image: url("../../../../common/asset/images/Section4_Field_Card_Image2.png");
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: -4px 4px 24px rgba(169, 166, 200, 0.25);

    @include tablet {
        width: 315px;
        height: 254px;
        margin-bottom: 0;
        padding: 16px;
        border-radius: 9px;
        background-image: url("../../../../common/asset/images/Section4_Field_Card_Image.png");
    }
    @include laptop {
        width: 420px;
        height: 338px;
        padding: 21px;
        border-radius: 11px;
    }
    @include desktop {
        width: 590px;
        height: 476px;
        padding: 30px;
        border-radius: 16px;
    }

    .section4-large-card-title {
        font-weight: 400;
        font-size: 24px;
        line-height: 22px;
        color: #ffffff;
        @include tablet {
            font-size: 10px;
            line-height: 12px;
        }
        @include laptop {
            font-size: 14px;
            line-height: 16px;
        }
        @include desktop {
            font-size: 20px;
            line-height: 22px;
        }
    }

    .section4-large-card-content {
        .section4-large-card-main-text {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #ffffff;
            @include tablet {
                font-size: 14px;
                line-height: 18px;
            }
            @include laptop {
                font-size: 20px;
                line-height: 25px;
            }
            @include desktop {
                font-size: 24px;
                line-height: 28px;
            }
        }

        .section4-large-card-link {
            width: 36px;
            object-fit: contain;
            cursor: pointer;
        }
    }
}
